<div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel" data-interval="5000">
  <ol class="carousel-indicators">
      <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="3 "></li>
    </ol>
  <div class="bd-example container py-4">
      <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="assets/images/ducho1.png" class="d-block w-100" alt="0">
            <div class="carousel-caption d-none d-md-block">
              <h4 style="color:black;">¡Disponible en tiendas!</h4>
            </div>
          </div>
          <div class="carousel-item">
            <img src="assets/images/ducho2.png" class="d-block w-100" alt="1">
            <div class="carousel-caption d-none d-md-block">
              <h4>¡Solicitar servicios!</h4>
              <p>Puedes solicitar servicios, mirar el perfil y calificación de los proveedores cerca a ti.</p>
            </div>
          </div>
          <div class="carousel-item">
            <img src="assets/images/ducho3.png" class="d-block w-100" alt="2">
            <div class="carousel-caption d-none d-md-block">
            </div>
          </div>
          <div class="carousel-item">
              <img src="assets/images/ducho4.png" class="d-block w-100" alt="3">
              <div class="carousel-caption d-none d-md-block">
                  <h5>¡Lo que necesites!</h5>
                  <p>Tienes una gran variedad de categorías entre las que puedes elegir.</p>
              </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
</div>