<app-carousel></app-carousel>
<div class="container py-4">
    <div class="jumbotron">
        <h1 class="display-4">¡Bienvenido a Ducho!</h1>
        <p>¡Ofrece y solicita todos tus servicios cerca a ti, cuando lo desees y de manera gratuita! Selecciónalos entre
            todas las categorías que la plataforma te ofrece. </p>
        <hr class="my-4">

        <h2 class="display-5">Funcionamiento de Ducho</h2>
        <div class="center-h center-v">


            <div>
                <!-- Trigger the modal with a button -->
                <button type="button" class="btn btn-primary btn-lg btn-space" data-toggle="modal"
                    data-target="#myModalPresentation">Presentación de Ducho</button>

                <!-- Modal -->
                <div class="modal fade" id="myModalPresentation" role="dialog">
                    <div class="modal-dialog">

                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-body">
                                <div class="embed-responsive embed-responsive-16by9">
                                    <iframe src="https://www.youtube.com/embed/6bzSne1Df80" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Trigger the modal with a button -->
                <button type="button" class="btn btn-primary btn-lg" data-toggle="modal"
                    data-target="#myModalOffer">Ofrece tus servicios</button>

                <!-- Modal -->
                <div class="modal fade" id="myModalOffer" role="dialog">
                    <div class="modal-dialog">

                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-body">
                                <div class="embed-responsive embed-responsive-16by9">
                                    <iframe src="https://www.youtube.com/embed/Vr3fSdk7vRA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="my-4">

            <p>Busca nuestra app en tu tienda de aplicaciones Android o IOS.</p>
            <div class="row">
                <div class="col-md-2 col-md-push-2">
                    <a href="https://play.google.com/store/apps/details?id=com.app.ducho" target="_blank ">
                        <img src="assets/images/app_google.png " alt="google play ">
                    </a>
                </div>
                <div class="col-md-2 col-md-pull-2">
                    <a href="https://apps.apple.com/co/app/ducho/id1528276757" target="_blank ">
                        <img class="size-img " src="assets/images/app_store.png " alt="app store ">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
