<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <h1>¿Quiénes somos?</h1>
            <div class="mx-auto" style="height: 32px;"></div>
            <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Verdana",sans-serif;'>Somos&nbsp;</span><strong><span style='font-family:"Verdana",sans-serif;'>DUCHO</span></strong><span style='font-family:"Verdana",sans-serif;'>, una empresa colombiana, cuya representante legal es Maria Gladis Marulanda Pati&ntilde;o, quien con su grupo de desarrolladores, ponen a disposici&oacute;n de toda la ciudadan&iacute;a sus conocimientos en diferentes tecnolog&iacute;as de desarrollo inform&aacute;tico.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Verdana",sans-serif;'>DUCHO&nbsp;</span></strong><span style='font-family:"Verdana",sans-serif;'>se crea a partir de la necesidad de encontrar personas que puedan requerir, brindar u ofrecer servicios que la comunidad a su alrededor est&aacute; solicitando.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Verdana",sans-serif;'>&nbsp;</span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:21px;font-family:"Verdana",sans-serif;'>MISI&Oacute;N:</span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Verdana",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Verdana",sans-serif;'>En
        <meta charset="utf-8"><strong style="font-weight: 700; color: rgb(0, 0, 0); font-family: Verdana, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial; text-decoration-color: initial;">DUCHO,&nbsp;</strong>somos concientes de la necesidad de encontrar qui&eacute;n puede brindar u ofrecer servicios de forma oportuna y cerca a la ubicaci&oacute;n del solicitante. P</span><span style='font-family:"Verdana",sans-serif;'>or ello, hemos creado a <strong>DUCHO</strong></span><span style='font-family:"Verdana",sans-serif;'>, una aplicaci&oacute;n m&oacute;vil, que pone a disposici&oacute;n de nuestros usuarios (proveedores) y&nbsp;</span><span style='font-family:"Verdana",sans-serif;'>clientes</span><span style='font-family:"Verdana",sans-serif;'>&nbsp;(consumidores) una plataforma que les permite establecer comunicaci&oacute;n entre ambas partes para generar v&iacute;nculos negociables de prestaci&oacute;n de servicios. Tenemos una amplia variedad de categor&iacute;as de servicios que se pueden seleccionar dentro de la plataforma.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Verdana",sans-serif;'>DUCHO</span></strong><span style='font-family:"Verdana",sans-serif;'>&nbsp;</span><span style='font-family:"Verdana",sans-serif;'>es el medio de comunicaci&oacute;n entre usuarios y clientes sin intermediarios, papeleos o pagos anticipados.&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Verdana",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:21px;font-family:"Verdana",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:21px;font-family:"Verdana",sans-serif;'>VISI&Oacute;N:</span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:21px;font-family:"Verdana",sans-serif;'>&nbsp;</span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-family:"Verdana",sans-serif;'>Como organizaci&oacute;n,&nbsp;</span><strong><span style='font-family:"Verdana",sans-serif;'>DUCHO</span></strong><span style='font-family:"Verdana",sans-serif;'>&nbsp;tiene</span><span style='font-family:"Verdana",sans-serif;'>&nbsp;como objetivo principal proveer la mejor experiencia, satisfacci&oacute;n y seguridad a todos nuestros clientes y usuarios en la solicitud y ofrecimiento de un servicio; modernizando y globalizando nuestra plataforma, garantizando su f&aacute;cil acceso, seguridad de la informaci&oacute;n, actualizaci&oacute;n y disponibilidad del servicio en todo momento.</span></p>
        </div>
    </div>
</div>