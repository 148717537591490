import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('#myModalPresentation').on('hide.bs.modal', () => {
      $('#myModalPresentation iframe').attr('src', $('#myModalPresentation iframe').attr('src'));
    });

    $('#myModalOffer').on('hide.bs.modal', () => {
      $('#myModalOffer iframe').attr('src', $('#myModalOffer iframe').attr('src'));
    });
  }

}
