<nav class="navbar navbar-expand-lg sticky-top navbar-light navbar-custom">
    <a class="navbar-brand" href="#">
        <img src="assets/images/isotipo-2.png" width="30" height="30" class="d-inline-block align-top" alt="" loading="lazy"> Ducho
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['about']">Acerca de nosotros</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['terms']">Términos y condiciones</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['contactus']">Contáctanos</a>
            </li>
        </ul>
        <span class="navbar-text">
        <div class="navbar navbar-expand-sm justify-content-end">
            <a href="https://www.facebook.com/Ducho-103764638113332/?modal=admin_todo_tour" class="fa urls" target="_blank"><img src="assets/images/facebook3.png" width="24" height="24"></a>
            <a href="https://www.instagram.com/duchoapp/" class="fa urls" target="_blank"><img src="assets/images/instagram3.png" width="24" height="24"></a>
            <a href="https://www.linkedin.com/in/ducho-app-9a86991b0/" class="fa urls" target="_blank"><img src="assets/images/linkedin3.png" width="24" height="24"></a>
        </div>
      </span>
    </div>
</nav>