<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <h1 class="pb-5">Déjanos tus datos y te responderemos muy pronto</h1>
        </div>

        <div class="col-md-8">
            <form action="/action_page.php">
                <div class="form-group row">
                    <label for="name" class="col-md-4 col-form-label">Nombre completo</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="name">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="email" class="col-md-4 col-form-label">Correo electrónico</label>
                    <div class="col-md-8">
                        <input type="email" class="form-control" id="email">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="cellphone" class="col-md-4 col-form-label">Número de celular</label>
                    <div class="col-md-8">
                        <input type="number" class="form-control" id="cellphone">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="msg" class="col-md-4 col-form-label">Escríbenos tu mensaje</label>
                    <div class="col-md-8">
                        <textarea class="form-control" id="msg" rows="3"></textarea>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-8 offset-md-4">
                        <button type="submit" class="btn btn-primary mr-2">Enviar</button>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-md-4">
            <ul class="social-networks">
                <li>
                    <a href="https://www.facebook.com/Ducho-103764638113332/?modal=admin_todo_tour" target="_blank">
                        <img src="assets/images/facebook2.png" alt="facebook">
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/duchoapp/" target="_blank">
                        <img src="assets/images/instagram2.png" alt="instagram">
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/ducho-app-9a86991b0/" target="_blank">
                        <img src="assets/images/linkedin2.png" alt="linkedin">
                    </a>
                </li>
                <li>
                    <a href="mailto:soporte@duchoapp.com" target="_blank">
                        <img src="assets/images/email.png" alt="email">
                    </a>
                </li>
            </ul>
        </div>

    </div>
</div>